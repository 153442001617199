<template>
  <div class="test-plans-add-runs py-2">
    <v-card class="test-plans-add-runs-header py-6 px-6 mb-2" rounded="lg" elevation="0" width="100%">
      <div class="d-flex align-center">
        <button plain class="btn-nav-back font-inter" @click="handleBackClick($event)">
          <v-icon color="blue">mdi-chevron-left</v-icon>
          <span class="d-flex-inline justify-center align-center ma-0 blue--text">
            {{ $t('plans.duplicate.rerun.back') }}
          </span>
        </button>
        <button class="btn-close ml-auto" @click="handleBackClick($event)">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>

      <h1 class="font-inter text-start mt-4">{{ $t('plans.duplicate.rerun.title') }}</h1>
      <div class="mt-4 d-flex">
        <v-chip
          class="font-inter font-weight-bold px-4"
          width="200px"
          label
          :class="{ 'blue--text': tableFilter === 'all' }"
          :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
          @click="changeFilter('all')"
        >
          {{ $t('all') }} <span class="ml-2">{{ testruns.length - selectedUserLength }}</span>
        </v-chip>
        <v-chip
          class="font-inter font-weight-bold px-4 ml-2"
          label
          width="200px"
          :class="{ 'blue--text': tableFilter === 'selected' }"
          :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
          @click="changeFilter('selected')"
        >
          {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
        </v-chip>
      </div>
    </v-card>
    <v-card class="test-plans-add-runs-wrapper pt-6 px-6" rounded="lg" elevation="0" width="100%">
      <div class="search-container d-flex">
        <search-field class="search-input" v-model="searchFilter" />

        <RunFilter @filters="applyFilters" />
        <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
          <template v-slot:activator="{ on }">
            <v-btn class="ml-auto pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
              <v-icon size="16px"> mdi-cog-outline </v-icon>
            </v-btn>
          </template>
          <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
            <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
            <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
              <v-checkbox
                v-model="header.isSelected"
                :value="header.isSelected"
                :true-value="true"
                :false-value="false"
                off-icon="mdi-checkbox-blank"
                class="ma-0 pa-0"
                color="blue"
                :size="16"
                hide-details
              >
                <template v-slot:label>
                  <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                </template>
              </v-checkbox>
            </v-list-item>
            <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
              <div
                class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
              >
                {{ $t('Restore default') }}
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <test-run-list
        :data="testruns"
        :tab="tableFilter"
        :headers="filteredHeaders"
        @updateUser="updateUser"
        @addConfiguration="handleAddConfiguration"
      />

      <v-row class="actions-container mt-4" justify="end">
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-menu v-model="menu" :close-on-content-click="false" offset-y top right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  depressed
                  color="gray-100"
                  class="font-inter text-capitalize font-weight-bold black--text mr-4 mt-2"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                >
                  {{ $t('actions') }} <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-list class="actions-list font-inter">
                <v-list-item class="actions-item" :to="{ name: 'TestPlanDuplicate' }">
                  <v-list-item-title>{{ $t('plans.create.duplicate') }}</v-list-item-title>
                </v-list-item>
                <v-list-item class="actions-item" :to="{ name: 'TestPlanAddRuns' }">
                  <v-list-item-title>{{ $t('plans.create.addTestRuns') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)"
              dark
              large
              depressed
              color="blue"
              class="font-inter font-weight-bold text-none mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="handleCreateTestPlan"
            >
              {{ $t('plans.createTestPlan') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <add-configuration-dialog :open="isOpenAddConfiguration" @close="isOpenAddConfiguration = false" />
  </div>
</template>
  
<script>
import SearchField from '@/components/Form/SearchField';
import ConfirmDialog from '@/views/Tests/Plans/Components/ConfirmDialog';
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';
import AddConfigurationDialog from '@/views/Tests/Plans/Components/Configuration/Index';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import { testruns } from '@/constants/data.js';
import {
  runStateMap,
  TestPlansTestRunsTableHeaderAll,
  TestPlansTestRunsTableHeaderSelected,
} from '@/constants/grid.js';
import { createNamespacedHelpers } from 'vuex';

export default {
  name: 'ProjectCreate',
  components: {
    SearchField,
    TestRunList,
    RunFilter,
    AddConfigurationDialog,
  },
  data() {
    return {
      imageSrc: '',
      testruns: testruns,
      form: {
        projectName: '',
        description: '',
        testruns: [],
      },
      isColumnFilter: false,
      showAddUsersView: true,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      headers: TestPlansTestRunsTableHeaderAll,
      isOpenAddConfiguration: false,
    };
  },
  computed: {
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedUserLength() {
      return this.testruns.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.testruns.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredHeaders() {
      const headers =
        this.tableFilter === 'all' ? TestPlansTestRunsTableHeaderAll : TestPlansTestRunsTableHeaderSelected;
      return headers.filter((x) => x.isSelected);
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    filteredMilestones() {
      if (this.searchFilter && this.mainFilter == false) {
        const filtered = this.milestones.filter((item) => this.matchesFilter(item));
        return filtered.length > 0 ? filtered : 'SEARCH_BACKEND';
      }
      return this.milestones;
    },
    milestones() {
      let filtered = this.items.filter((item) => item.customFields.runstatus === this.filter);

      return filtered.map((item) => ({
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
      }));
    },
  },
  mounted() {
    this.testruns.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
  },
  watch: {
    currentOrg: 'refreshData',
    filteredMilestones(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
  },
  methods: {
    handleAddConfiguration(value) {
      this.isOpenAddConfiguration = true;
    },
    handleCreateTestPlan() {
      console.log('Not implemented');
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick(event) {
      event.preventDefault();

      this.$router.replace({ name: 'TestPlanCreate' });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
      this.$router.push({ name: 'MilestoneCreate' });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addUsers() {
      this.testruns.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removeUsers() {
      this.testruns.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    updateUser(user) {
      const index = this.testruns.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);
      const sourceMatch = item.source.toLowerCase().includes(lowerCaseFilter);

      return nameMatch || sourceMatch;
    },
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.test-plans-add-runs {
  height: calc(100vh - 90px - 8px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-add-runs-header {
  flex: 0 1 138px;
}

.test-plans-add-runs-wrapper {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 68px;

  display: flex;
  flex-direction: column;
}

.search-container {
  flex: 0 1 40px;
}

.plans-list {
  flex: 1 1 auto;
  overflow: auto;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;

  display: flex;
  align-items: center;
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
